import React from "react";
import { connect } from "react-redux";

import { BasePureComponent } from "common/components/Base";
import { currentDate } from "common/util";
import "./styles.scss";

/* The application's footer. */
class Footer extends BasePureComponent {
  render() {
    // parent
    super.render();

    // render
    return (
      <div className="container-fluid fsp-footer fsp-background d-print-none">
        <div className="no-gutters row fsp-footer-container">
          <div className="col text-right fsp-footer-links">
            Freedom School Partners &copy;&nbsp;{currentDate().year()}
          </div>
        </div>
      </div>
    );
  }
}

// turn this into a container component
Footer = connect()(Footer);

// set prop types and required-ness
Footer.propTypes = {};

// set default props
Footer.defaultProps = {};

export default Footer;
